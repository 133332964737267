<template>
  <Loading v-if="isLoading" />
  <section class="background-login content">
    <div class="container">
      <div class="wrapper-elements-form-register">
        <div>
          <div>
            <Logo />
          </div>
          <div class="form-container-login card is-relative mt-5 py-3">
            <div class="img-header-icon">
              <div class="container-header-icon">
                <img
                  src="../assets/img/icon-cambio-passwd.svg"
                  width="32"
                  height="32"
                  alt="Cambio de contrase&ntilde;a"
                  class="is-block"
                />
              </div>
            </div>
            <div class="card-content mt-5">
              <Form
                id="form-create-password"
                :validation-schema="schema"
                @submit="sedFormCreatePassword"
                v-slot="{ errors }"
              >
                <h2 class="has-text-centered">Crear contrase&ntilde;a</h2>
                <div class="field mt-5">
                  <div class="control has-icons-left">
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'key']" />
                    </span>
                    <span class="password-eye">
                      <Field
                        v-show="showPassword"
                        v-model="password"
                        name="password"
                        class="input"
                        placeholder="Ejemplo: #PQxCWWFHO1o"
                        type="text"
                        :validateOnInput="true"
                      />
                      <Field
                        v-show="!showPassword"
                        v-model="password"
                        name="password"
                        class="input"
                        placeholder="Ejemplo: #PQxCWWFHO1o"
                        type="password"
                        :validateOnInput="true"
                      />
                      <a class="button" @click="toggleShow">
                        <span class="icon is-small" @click="toggleShow">
                          <font-awesome-icon v-if="showPassword" :icon="['far', 'eye']" />
                          <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
                        </span>
                      </a>
                    </span>
                    <div class="has-text-primary">{{ errors.password }}</div>
                  </div>
                  <p class="help">
                    Debe tener n&uacute;meros, s&iacute;mbolos, min&uacute;sculas, may&uacute;sculas
                  </p>
                </div>
                <div class="field mt-5">
                  <div class="control has-icons-left">
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'key']" />
                    </span>
                    <span class="password-eye">
                      <Field
                        v-show="showPasswordConfirm"
                        v-model="confirmPassword"
                        name="passwordConfirm"
                        class="input"
                        placeholder="Ejemplo: #PQxCWWFHO1o"
                        type="text"
                        :validateOnInput="true"
                      />
                      <Field
                        v-show="!showPasswordConfirm"
                        v-model="confirmPassword"
                        name="passwordConfirm"
                        class="input"
                        placeholder="Ejemplo: #PQxCWWFHO1o"
                        type="password"
                        :validateOnInput="true"
                      />
                      <a class="button" @click="togglePasswordConfirm">
                        <span class="icon is-small" @click="togglePasswordConfirm">
                          <font-awesome-icon v-if="showPasswordConfirm" :icon="['far', 'eye']" />
                          <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
                        </span>
                      </a>
                    </span>
                    <ErrorMessage name="passwordConfirm" class="has-text-primary" as="p" />
                  </div>
                  <p class="help">Repita la contrase&ntilde;a</p>
                </div>
                <div class="field mt-5">
                  <p class="has-text-centered">
                    <button class="button is-primary">Crear Contrase&ntilde;a</button>
                  </p>
                  <p class="has-text-centered">
                    <router-link to="/" class="has-text-primary"
                      >Inicio de sesi&oacute;n</router-link
                    >
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import Loading from '@/components/Loading.vue';
import Logo from './Logo.vue';

const yup = require('yup');
require('yup-password')(yup);

export default {
  name: 'FormCreatePassword',
  data() {
    const schema = yup.object(
      {
        password: yup
          .string()
          .required('Contraseña requerida')
          .password()
          .minLowercase(1, 'Debe contener al menos una letra minúscula')
          .minUppercase(1, 'Debe contener al menos una letra mayúscula')
          .minSymbols(1, 'Debe contener al menos un símbolo como #@? etc')
          .min(8, 'Debe contener mínimo 8 caracteres')
          .minNumbers(1, 'Debe contener al menos un número')
          .max(30, 'La contraseña no puede tener mas de 30'),
        passwordConfirm: yup
          .string()
          .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
      },
      true,
    );
    return {
      schema,
      pathAccept: '',
      password: '',
      confirmPassword: '',
      isLoading: false,
      showPassword: false,
      showPasswordConfirm: false,
    };
  },
  created() {
    this.pathAccept = this.$route.params;
  },
  components: {
    Logo,
    Field,
    ErrorMessage,
    Form,
    Loading,
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },
    async sedFormCreatePassword() {
      this.isLoading = true;
      try {
        const createPassword = await this.axios.post(
          `${process.env.VUE_APP_API_ROOT}${process.env.VUE_APP_REGISTER}`,
          {
            password: this.password,
            token: this.pathAccept.token,
          },
        );
        if (createPassword.status === 200) {
          this.isLoading = false;
          await this.$swal.fire(
            {
              title: 'Asignación de contrase&ntilde;a exitosa',
              icon: 'success',
              html: `<p>
                    <strong>Contrase&ntilde;a asignada.</strong>
                 </p>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#96092d',
              confirmButtonText: 'Iniciar sesión',
              timer: '5000',
            },

            await setTimeout(() => {
              this.$router.push({ name: 'Home' });
            }, 5000),
          );
        }
      } catch (error) {
        this.isLoading = false;
        if (error.response === undefined) {
          await this.$swal.fire({
            title: 'Error al asignar la contrase&ntilde;a',
            icon: 'error',
            html: '<strong>Error al crear la contrase&ntilde;a. Intente mas tarde</strong>',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          });
        } else {
          switch (error.response.status) {
            case 400:
              if (error.response.data.errorCode === 'NOT_FOUND') {
                await this.$swal.fire({
                  title: 'No existe la cuenta',
                  icon: 'warning',
                  html: '<strong>Parece que usted no tiene cuenta asignada. Dir&iacute;jase a una de nuestras oficinas o pongase en contacto con uno de nuestros asesores</strong>',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#96092d',
                  confirmButtonText: 'Aceptar',
                });
              }
              if (error.response.data.errorCode === 'INVALID_PROPERTY') {
                await this.$swal.fire({
                  title: 'No existe la cuenta',
                  icon: 'warning',
                  html: '<strong>La contrase&ntilde;a ingresada no cumple con el patrón recomendado. Debe terner letras may&uacute;sculas, letras min&uacute;sculas, m&iacute;nimo 8 carateres, máximo 30 caacteres, debe ingresar simbolos como #, ?, @, etc.</strong>',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#96092d',
                  confirmButtonText: 'Aceptar',
                });
              }
              break;
            default:
              break;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-elements-form-register {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 1024px) {
    margin-left: 2rem;
  }
}
</style>
