import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Register from '@/views/Register.vue';
import CreatePassword from '@/views/CreatePassword.vue';
import Profile from '@/views/Profile.vue';
import RecoveryPassword from '@/views/RecoveryPassword.vue';
import PasswordChange from '@/views/PasswordChange.vue';
import UserData from '@/views/UserData.vue';
import NotFoundComponent from '@/views/NotFoundComponent.vue';
import ListBeneficiaries from '@/views/ListBeneficiaries.vue';
import Payments from '@/views/Payments.vue';
import Notification from '@/views/Notification.vue';
// eslint-disable-next-line import/no-cycle
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { initApp: true, titlePage: 'Inicio' },
  },
  {
    path: '/registro',
    name: 'Register',
    component: Register,
    meta: { titlePage: 'Registro' },
  },
  {
    path: '/crear-password/:token',
    name: 'CreatePassword',
    component: CreatePassword,
    meta: { titlePage: 'Contraseña' },
  },
  {
    path: '/recuperar-password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: { titlePage: 'Recuperar Contraseña' },
  },
  {
    path: '/informacion-usuario',
    name: 'UserData',
    component: UserData,
    meta: { requireAuth: true, titlePage: 'Información Usuario' },
  },
  {
    path: '/recuperar-password-usuario',
    name: 'RecoveryPasswordUser',
    component: PasswordChange,
    meta: { requireAuth: true, titlePage: 'Cambio contraseña' },
  },
  {
    path: '/escritorio',
    name: 'dashboard',
    component: Profile,
    meta: { requireAuth: true, titlePage: 'Contratos' },
  },
  {
    path: '/contrato/:id/beneficiarios',
    name: 'ListBeneficiaries',
    component: ListBeneficiaries,
    meta: { requireAuth: true, titlePage: 'Beneficiarios' },
  },
  {
    path: '/contrato/pagos/:id',
    name: 'Payment',
    component: Payments,
    meta: { requireAuth: true, titlePage: 'Listado de pagos' },
  },
  {
    path: '/notificaciones',
    name: 'Notifications',
    component: Notification,
    meta: { requireAuth: true, titlePage: 'Listado de pagos' },
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: 'NotFound',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Títulos de rutas
  const titlePage = to.matched.some((record) => record.meta.titlePage);
  if (titlePage) {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.matched[0].meta.titlePage}`;
  }

  // Protección de rutas
  const requireAuth = to.matched.some((record) => record.meta.requireAuth);
  const loginNotRequiredAuth = to.matched.some((record) => record.meta.initApp);
  if (loginNotRequiredAuth && store.state.appToken !== '') {
    next({ name: 'dashboard' });
  } else if (requireAuth && store.state.appToken === '') {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
