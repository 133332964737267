<template>
  <h1 class="title is-4 px-4">Beneficiarios contrato {{ id }}</h1>
  <div class="data-contracts px-4">
    <button class="button is-primary mb-4 is-small" @click.prevent="$router.go(-1)">
      <span class="icon is-small">
        <font-awesome-icon icon="arrow-left" />
      </span>
    </button>
    <div class="table-container">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Documento</th>
            <th>Edad</th>
            <th>Relación</th>
            <!--            <th>Tipo</th>-->
            <th>Fecha Vinculación</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in register" v-bind:key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.document }}</td>
            <td>{{ item.age }}</td>
            <td>{{ item.relationship }}</td>
            <!--            <td>{{ item.animal === null ? 'HUMANO' : 'MASCOTA - '
               + item.animal }}</td>-->
            <td>{{ formatDate(item.initialDate) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="numberRegister > limit" class="px-4 mt-5">
    <nav class="pagination is-rounded" role="navigation" aria-label="pagination">
      <router-link
        :to="{ query: { page: currentPage > 1 ? currentPage - 1 : 1 } }"
        class="pagination-previous"
        :disabled="currentPage === 1 ? true : null"
      >
        Atr&aacute;s
      </router-link>
      <router-link
        :to="{ query: { page: numberPages > currentPage ? currentPage + 1 : numberPages } }"
        class="pagination-next"
        :disabled="currentPage === numberPages ? true : null"
      >
        Siguiente
      </router-link>
      <ul class="pagination-list">
        <li v-for="(item, index) in numberPages" :key="index">
          <router-link
            v-bind:class="{ 'pagination-link': true, 'is-current': currentPage === index + 1 }"
            :to="{ query: { page: item } }"
          >
            {{ index + 1 }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'Beneficiaries',
  data() {
    return {
      id: this.$route.params.id,
      currentPage: 1,
      register: [],
      numberRegister: '',
      numberPages: '',
      limit: 20,
    };
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler(page) {
        this.currentPage = page || 1;
        this.dataPages(parseInt(this.currentPage, 10));
      },
    },
  },
  computed: {
    ...mapState(['appToken']),
  },
  methods: {
    async dataPages(page) {
      try {
        const skip = (page - 1) * this.limit + 1;
        const elementsBeneficiaries = await this.axios.get(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/contracts/${this.id}/beneficiary`,
          {
            params: {
              limit: this.limit,
              offset: skip,
            },
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );
        this.register = elementsBeneficiaries.data.beneficiaries;
        this.numberRegister = elementsBeneficiaries.data.numberOfElements;
        this.numberPages = Math.ceil(this.numberRegister / this.limit);
        this.currentPage = page;
      } catch (error) {
        /* await this.$swal.fire(
          {
            title: 'Sesión de usuario',
            icon: 'error',
            html: `
             <p>
             La sesión de usuario ha expirado o no est&aacute; autorizado para ver esta sección.
             </p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            timer: 3000,
          },
        ); */
        await this.actionCloseSessionUser();
        await this.$router.push({ name: 'Home' });
      }
    },
    ...mapActions(['actionCloseSessionUser']),
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
};
</script>
