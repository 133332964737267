<template>
  <h1 class="title is-4 px-4">Listado de pagos contrato {{ id }}</h1>
  <div class="data-contracts px-4">
    <button class="button is-primary mb-4 is-small" @click.prevent="$router.go(-1)">
      <span class="icon is-small">
        <font-awesome-icon icon="arrow-left" />
      </span>
    </button>
    <div class="table-container">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Medio de pago</th>
            <th>Pago desde</th>
            <th>Pago hasta</th>
            <th>Cantidad pagos</th>
            <th>Valor unitario</th>
            <th>Valor total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in register" v-bind:key="index">
            <td>{{ formatDate(item.collectionDate) }}</td>
            <td>{{ item.collectionChannelName }}</td>
            <td>{{ formatDate(item.coverageFromDate) }}</td>
            <td>{{ formatDate(item.coverageUntilDate) }}</td>
            <td>{{ item.payments }}</td>
            <td>{{ formatNumber(item.unitValue) }}</td>
            <td>{{ formatNumber(item.totalValue) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="numberRegister > limit" class="px-4 mt-5">
    <nav class="pagination is-rounded" role="navigation" aria-label="pagination">
      <router-link
        :to="{ query: { page: currentPage > 1 ? currentPage - 1 : 1 } }"
        class="pagination-previous"
        :disabled="currentPage === 1 ? true : null"
      >
        Atrás
      </router-link>
      <router-link
        :to="{ query: { page: numberPages > currentPage ? currentPage + 1 : numberPages } }"
        class="pagination-next"
        :disabled="currentPage === numberPages ? true : null"
      >
        Siguiente
      </router-link>
      <ul class="pagination-list">
        <li v-for="(item, index) in numberPages" :key="index">
          <router-link
            v-bind:class="{ 'pagination-link': true, 'is-current': currentPage === index + 1 }"
            :to="{ query: { page: item } }"
          >
            {{ index + 1 }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'ListPayments',
  data() {
    return {
      id: this.$route.params.id,
      currentPage: 1,
      register: [],
      numberRegister: '',
      numberPages: '',
      limit: 20,
    };
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler(page) {
        this.currentPage = page || 1;
        this.dataPages(parseInt(this.currentPage, 10));
      },
    },
  },
  computed: {
    ...mapState(['appToken']),
  },
  methods: {
    async dataPages(page) {
      try {
        const skip = (page - 1) * this.limit + 1;
        const elementsPayments = await this.axios.get(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/payments/${this.id}`,
          {
            params: {
              limit: this.limit,
              offset: skip,
            },
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );
        this.register = elementsPayments.data.payments;
        console.log(this.register);
        this.numberRegister = elementsPayments.data.numberOfElements;
        this.numberPages = Math.ceil(this.numberRegister / this.limit);
        this.currentPage = page;
      } catch (error) {
        await this.$swal.fire({
          title: 'Sesión de usuario',
          icon: 'error',
          html: `
             <p>
             La sesi&oacute;n de usuario ha expirado o no est&aacute; autorizado para ver esta secci&oacute;n.
             </p>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#96092d',
          timer: 3000,
        });
        await this.actionCloseSessionUser();
        await this.$router.push({ name: 'Home' });
      }
    },
    ...mapActions(['actionCloseSessionUser']),
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatNumber(number) {
      const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0,
      });
      return formatter.format(number);
    },
  },
};
</script>
