<template>
  <div class="section-user px-2">
    <header class="container">
      <div class="section-logo py-4">
        <Logo />
      </div>
    </header>
    <div class="container main-content-structure">
      <main class="has-background-white box-rounded py-5 mb-6">
        <FormNotification />
      </main>
      <footer class="has-background-white box-rounded mb-6">
        <Footer />
      </footer>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import FormNotification from '@/components/FormNotification.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Notification',
  components: {
    FormNotification,
    Logo,
    Footer,
  },
};
</script>

<style scoped></style>
