import { createStore } from 'vuex';
import jwtDecode from 'jwt-decode';

// eslint-disable-next-line import/no-cycle
import router from '../router';

export default createStore({
  state: {
    appToken: '',
    dataUser: '',
  },
  mutations: {
    getUser(state, payload) {
      state.appToken = payload;
      if (payload === '') {
        state.dataUser = '';
      } else {
        state.dataUser = jwtDecode(payload);
      }
    },
    closeSessionUser(state) {
      state.dataUser = '';
      state.appToken = '';
    },
  },
  actions: {
    actionGetUser({ commit }, payload) {
      localStorage.setItem('appToken', payload);
      commit('getUser', payload);
      router.push({ name: 'dashboard' });
    },
    actionReadAppToken({ commit }) {
      const token = localStorage.getItem('appToken');
      if (token) {
        commit('getUser', token);
      } else {
        commit('getUser', '');
      }
    },
    actionCloseSessionUser({ commit }) {
      localStorage.removeItem('appToken');
      commit('closeSessionUser');
    },
  },
  getters: {
    getNameUser(state) {
      if (state.dataUser !== '' && state.dataUser.firstName) {
        return `${state.dataUser.firstName}`;
      }
      return '';
    },
  },
  modules: {},
});
