<template>
  <Loading v-if="isLoading" />
  <div class="container">
    <h2 class="title has-text-centered is-4 mt-4 mb-4">Cambio de contrase&ntilde;a</h2>
    <div class="columns">
      <div class="column is-6-desktop is-offset-3-desktop">
        <div class="form-container-loginis-relative">
          <div>
            <Form
              id="form-create-password"
              :validation-schema="schema"
              @submit="sendFormPasswordChange"
              v-slot="{ errors }"
            >
              <div class="field mt-5">
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'key']" />
                  </span>
                  <span class="password-eye">
                    <Field
                      v-show="showPassword"
                      v-model="password"
                      name="password"
                      class="input"
                      placeholder="Ejemplo: #PQxCWWFHO1o"
                      type="text"
                      :validateOnInput="true"
                    />
                    <Field
                      v-show="!showPassword"
                      v-model="password"
                      name="password"
                      class="input"
                      placeholder="Ejemplo: #PQxCWWFHO1o"
                      type="password"
                    />
                    <a class="button" @click="toggleShow">
                      <span class="icon is-small" @click="toggleShow">
                        <font-awesome-icon v-if="showPassword" :icon="['far', 'eye']" />
                        <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
                      </span>
                    </a>
                  </span>
                  <div class="has-text-primary">{{ errors.password }}</div>
                </div>
                <p class="help">
                  Contrase&ntilde;a: Debe tener n&uacute;meros, s&iacute;mbolos, min&uacute;sculas,
                  may&uacute;sculas
                </p>
              </div>
              <div class="field mt-5">
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'key']" />
                  </span>
                  <span class="password-eye">
                    <Field
                      v-show="showPasswordConfirm"
                      v-model="passwordConfirm"
                      name="passwordConfirm"
                      class="input"
                      placeholder="Ejemplo: #PQxCWWFHO1o"
                      type="text"
                      :validateOnInput="true"
                    />
                    <Field
                      v-show="!showPasswordConfirm"
                      v-model="passwordConfirm"
                      name="passwordConfirm"
                      class="input"
                      placeholder="Ejemplo: #PQxCWWFHO1o"
                      type="password"
                    />
                    <a class="button" @click="togglePasswordConfirm">
                      <span class="icon is-small" @click="togglePasswordConfirm">
                        <font-awesome-icon v-if="showPasswordConfirm" :icon="['far', 'eye']" />
                        <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
                      </span>
                    </a>
                  </span>
                  <ErrorMessage name="passwordConfirm" class="has-text-primary" as="p" />
                </div>
                <p class="help">Repita la contrase&ntilde;a</p>
              </div>
              <div class="field mt-5">
                <p class="has-text-centered">
                  <button class="button is-primary">Actualizar Contrase&ntilde;a</button>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';

const yup = require('yup');
require('yup-password')(yup);

export default {
  name: 'FormPasswordChange',
  data() {
    const schema = yup.object(
      {
        password: yup
          .string()
          .required('Contraseña requerida')
          .password()
          .minLowercase(1, 'Debe contener al menos una letra minúscula')
          .minUppercase(1, 'Debe contener al menos una letra mayúscula')
          .minSymbols(1, 'Debe contener al menos un símbolo como #@? etc')
          .min(8, 'Debe contener mínimo 8 caracteres')
          .minNumbers(1, 'Debe contener al menos un número')
          .max(30, 'La contraseña no puede tener mas de 30'),
        passwordConfirm: yup
          .string()
          .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
      },
      true,
    );
    return {
      schema,
      password: '',
      passwordConfirm: '',
      isLoading: false,
      showPassword: false,
      showPasswordConfirm: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Loading,
  },
  computed: {
    ...mapState(['appToken']),
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },
    async sendFormPasswordChange() {
      this.isLoading = true;
      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_ROOT + process.env.VUE_APP_UPDATE_PASSWORD,
          { password: this.password },
          {
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );

        if (response.status === 200) {
          this.isLoading = false;
          await this.$swal.fire({
            title: 'La contraseña se cambió con éxito',
            icon: 'success',
            html: `
             <p>
             La contraseña se actualizó correctamente.
             </p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            timer: 5000,
          });
          this.password = '';
          this.passwordConfirm = '';
        }
      } catch (error) {
        this.isLoading = false;
        switch (error.status) {
          case undefined:
            await this.$swal.fire({
              title: 'Error de red',
              icon: 'error',
              html: `
             <p>
             En el momento hay problemas de red. Pruebe mas tarde.
             </p>
            `,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#96092d',
              timer: 5000,
            });
            break;
          case 403:
            await this.$swal.fire({
              title: 'Sesión de usuario',
              icon: 'error',
              html: `
             <p>
             La sesión de usuario ha expirado o no está autorizado para ver esta sección.
             </p>
            `,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#96092d',
              timer: 5000,
            });
            break;
          default:
            break;
        }
        await this.actionCloseSessionUser();
        await this.$router.push({ name: 'Home' });
      }
    },
    ...mapActions(['actionCloseSessionUser']),
  },
};
</script>

<style lang="scss"></style>
