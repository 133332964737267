<template>
  <div>
    <div class="columns">
      <div class="column is-8-desktop is-offset-2-desktop">
        <div class="form-container-loginis-relative">
          <div>
            <Form id="form-data-user" @submit="sendFormInfoUser">
              <div class="field mt-1">
                <label class="label" for="id"
                  >N&uacute;mero de documento de identificaci&oacute;n</label
                >
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'address-card']" />
                  </span>
                  <Field
                    id="id"
                    v-model="infoUser.id"
                    name="id"
                    class="input"
                    placeholder="N&uacute;mero de identificaci&oacute;n"
                    type="text"
                    disabled
                  />
                </div>
              </div>
              <div class="field mt-3">
                <label class="label" for="address">Direcci&oacute;n de domicilio</label>
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
                  </span>
                  <Field
                    id="address"
                    v-model="infoUser.address"
                    name="address"
                    class="input"
                    placeholder="Ejemplo: CL 53 63AA 61 APT 301 Bello Antioquia"
                    type="text"
                    :validateOnInput="true"
                  />
                </div>
              </div>
              <div class="field mt-3">
                <label class="label" for="mobile-number"
                  >N&uacute;mero de tel&eacute;fono m&oacute;vil</label
                >
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'mobile-alt']" />
                  </span>
                  <Field
                    id="mobile-number"
                    v-model="infoUser.cellPhoneNumber"
                    class="input"
                    placeholder="Ejemplo: 3184887584"
                    type="tel"
                    name="mobile"
                  />
                </div>
              </div>
              <div class="field mt-3">
                <label class="label" for="phone-contact">Tel&eacute;fono de contacto</label>
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'phone']" />
                  </span>
                  <Field
                    id="phone-contact"
                    v-model="infoUser.phoneNumber"
                    class="input"
                    placeholder="Ejemplo: 4567895"
                    type="tel"
                    :validateOnInput="true"
                    name="phoneContact"
                  />
                </div>
              </div>
              <div class="field mt-3">
                <label class="label" for="email-user">Correo Electr&oacute;nico</label>
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'envelope-open-text']" />
                  </span>
                  <Field
                    id="email-user"
                    v-model="infoUser.email"
                    name="emailUser"
                    class="input"
                    placeholder="john@enlacima.co"
                    type="email"
                    :validateOnInput="true"
                  />
                </div>
              </div>
              <div class="field mt-5">
                <p class="has-text-centered">
                  <button class="button is-primary is-small">Actualizar Informaci&oacute;n</button>
                  <router-link
                    style="border: none"
                    class="button bg-color-blue ml-2 has-text-white is-small"
                    :to="{ name: 'RecoveryPasswordUser' }"
                  >
                    Contrase&ntilde;a
                  </router-link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import { mapState, mapActions } from 'vuex';

// const yup = require('yup');

export default {
  name: 'UserData',
  data() {
    return {
      infoUser: {
        address: '',
        cellPhoneNumber: '',
        id: '',
        phoneNumber: '',
        email: '',
      },
    };
  },
  components: {
    Form,
    Field,
  },
  computed: {
    ...mapState(['appToken']),
  },
  async mounted() {
    try {
      const response = await this.axios.get(
        process.env.VUE_APP_API_ROOT_CONTRACT + process.env.VUE_APP_INFO_CLIENT,
        {
          headers: {
            Authorization: `Bearer ${this.appToken}`,
          },
        },
      );

      if (response.status === 200) {
        this.infoUser.address = response.data.address;
        this.infoUser.id = response.data.id;
        this.infoUser.email = response.data.email;
        this.infoUser.phoneNumber = response.data.phoneNumber;
        this.infoUser.cellPhoneNumber = response.data.cellphoneNumber;
      }
    } catch (error) {
      await this.$swal.fire({
        title: 'Sesi&oacute;n Cerrada',
        icon: 'warning',
        html: `
             <p>La sesi&oacute;n ha sido cerrada por caducidad de tiempo</p>
            `,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#96092d',
        confirmButtonText: 'Aceptar',
        timer: 5000,
      });
      await this.actionCloseSessionUser();
    }
  },
  methods: {
    async sendFormInfoUser() {
      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_ROOT_CONTRACT + process.env.VUE_APP_INFO_CLIENT,
          {
            address: this.infoUser.address === '' ? null : this.infoUser.address,
            cellphoneNumber:
              this.infoUser.cellPhoneNumber === '' ? null : this.infoUser.cellPhoneNumber,
            email: this.infoUser.email === '' ? null : this.infoUser.email,
            phoneNumber: this.infoUser.phoneNumber === '' ? null : this.infoUser.phoneNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );

        if (response.status >= 200 && response.status < 300) {
          await this.$swal.fire({
            title: 'Actualizaci&oacute;n de informaci&oacute;n',
            icon: 'success',
            html: `
             <p>
             Informaci&oacute;n de usuario actualizada correctamente.
             </p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
          });
        } else if (response.status >= 400 && response.status < 500) {
          await this.$swal.fire({
            title: 'La sesion ha caducado',
            icon: 'warning',
            html: `
             <p>
             Problemas de aplicaci&oacute;n. Pruebe mas tarde.
             </p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            timer: 5000,
          });
        }
      } catch (error) {
        switch (error.status) {
          case undefined:
            await this.$swal.fire({
              title: 'Error de la aplicaci&oacute;n. Intente mas tarde',
              icon: 'error',
              html: `
             <p>
             En el momento hay problemas de red. Pruebe más tarde.
             </p>
            `,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#96092d',
              timer: 5000,
            });
            break;
          default:
            break;
        }
      }
    },
    ...mapActions(['actionCloseSessionUser']),
  },
};
</script>

<style lang="scss"></style>
