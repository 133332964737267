<template>
  <div class="px-2">
    <div class="container">
      <div class="columns">
        <div class="column is-12-desktop center-flexbox">
          <div class="form-container-login is-relative">
            <h2 class="title has-text-centered is-4 mt-4 mb-4">Contáctenos</h2>
            <div>
              <p class="help mb-2">
                Si presenta alguna duda o inquietud, puede contactarse a través de los siguientes
                canales de comunicaci&oacute;n:
              </p>
              <ul>
                <li class="mb-2">
                  <a
                    href="https://api.whatsapp.com/send?phone=573207063313"
                    target="_blank"
                    class="is-flex center-icon-text has-text-black"
                  >
                    <img width="36" height="36" src="../assets/img/whatsapp.svg" alt="WhatsApp" />
                    &nbsp;
                    <span>320 706 3313 </span>
                  </a>
                </li>
                <li class="mb-2">
                  <a
                    href="mailto:fsanvicente@funerariasanvicente.com"
                    target="_blank"
                    class="is-flex center-icon-text has-text-black"
                  >
                    <img width="36" height="36" src="../assets/img/email.svg" alt="WhatsApp" />
                    &nbsp;
                    <span>fsanvicente@funerariasanvicente.com</span>
                  </a>
                </li>
                <li class="mb-2">
                  <a
                    href="tel:6045755050"
                    target="_blank"
                    class="is-flex center-icon-text has-text-black"
                  >
                    <img width="36" height="36" src="../assets/img/tel-fijo.svg" alt="WhatsApp" />
                    &nbsp;
                    <span>604 575 5050</span>
                  </a>
                </li>
              </ul>

              <p class="help color-text-blue">Escr&iacute;banos sus aportes y sugerencias</p>
              <form id="form-create-notification" @submit.prevent="sendFormNotification">
                <div class="field mt-5">
                  <div class="control">
                    <textarea
                      minlength="20"
                      maxlength="1000"
                      required
                      v-model="message"
                      name="notification"
                      class="textarea"
                      placeholder="Mensaje"
                    >
                    </textarea>
                  </div>
                </div>
                <div class="field mt-5">
                  <p class="has-text-centered">
                    <button class="button is-primary">Enviar notificaci&oacute;n</button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FormNotification',
  data() {
    return {
      message: '',
    };
  },
  methods: {
    ...mapActions(['actionCloseSessionUser']),
    async sendFormNotification() {
      try {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/notifications`,
          {
            emailCode: process.env.VUE_APP_CODE_NOTIFICATION,
            message: this.message,
          },
          {
            headers: {
              Authorization: `Bearer ${this.appToken}`,
              'Content-type': 'application/json',
            },
          },
        );

        if (response.status >= 200 && response.status < 300) {
          await this.$swal.fire({
            title: 'Notificaci&oacute;n enviada',
            icon: 'success',
            html: `
             <p>Notificaci&oacute;n enviada con éxito.</p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          });
          this.message = '';
        } else if (response.status >= 400 && response.status < 500) {
          await this.$swal.fire({
            title: 'Advertencia de sesion',
            icon: 'warning',
            html: `
             <p>La sesion ha expirado o no tiene permiso para ver esta seccion.</p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
            timer: 5000,
          });
          this.actionCloseSessionUser();
          this.$router.push('Home');
        }
      } catch (error) {
        await this.$swal.fire({
          title: 'Problemas de aplicaci&oacute;n',
          icon: 'error',
          html: `
             <p>La aplicaci&oacute;n ha presentado problemas. Vuelva a intentarlo mas tarde</p>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#96092d',
          confirmButtonText: 'Aceptar',
          timer: 5000,
        });
      }
    },
  },
  computed: {
    ...mapState(['appToken']),
  },
};
</script>

<style scoped>
.center-icon-text {
  align-items: center;
}
</style>
