<template>
  <p>Página no encontrada</p>
</template>

<script>
export default {
  name: 'NotFoundComponent',
};
</script>

<style scoped></style>
