<template>
  <Loading v-if="isLoading" />
  <section class="background-login px-2">
    <div class="container">
      <div class="wrapper-elements-form-register">
        <div>
          <Logo />
        </div>
        <div class="form-container-login card is-relative py-3">
          <div class="img-header-icon">
            <div class="container-header-icon">
              <img
                src="../assets/img/user.svg"
                width="32"
                height="32"
                alt="Usuario"
                class="is-block"
              />
            </div>
          </div>
          <div class="card-content mt-2">
            <Form
              id="form-recovery-password"
              :validation-schema="schema"
              @submit="sendFormRecoveryPassword"
            >
              <h2 class="has-text-centered">Recuperar contraseña</h2>
              <div class="field mt-5">
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'address-card']" />
                  </span>
                  <Field
                    v-model="id"
                    name="id"
                    class="input"
                    placeholder="712114369"
                    type="text"
                    :validateOnInput="true"
                    min="6"
                    max="11"
                  />
                  <ErrorMessage name="id" class="has-text-primary" as="p" />
                </div>
                <p class="help">Número de cédula del titular, solo números enteros</p>
              </div>
              <div class="field mt-5">
                <p class="has-text-centered">
                  <button type="submit" class="button is-primary">Enviar solicitud</button>
                </p>
                <p class="has-text-centered">
                  <router-link to="/" class="has-text-primary">Inicio de sesión</router-link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ErrorMessage, Field, Form } from 'vee-validate';
import * as yup from 'yup';
import Loading from '@/components/Loading.vue';
import Logo from './Logo.vue';

export default {
  name: 'FormRecoveryPassword',
  data() {
    const schema = yup.object({
      id: yup
        .string()
        .required('Requerido')
        .matches(/[0-9]+$/, { message: 'Solo números enteros' })
        .min(6, 'Mínimo 6 caracteres')
        .max(11, 'Máximo 11 caracteres'),
    });
    return {
      schema,
      id: '',
      isLoading: false,
    };
  },
  components: {
    Logo,
    Field,
    ErrorMessage,
    Form,
    Loading,
  },
  methods: {
    async sendFormRecoveryPassword() {
      this.isLoading = true;
      try {
        const { status, data } = await this.axios.get(
          process.env.VUE_APP_API_ROOT + process.env.VUE_APP_RECOVERY_PASSWORD + this.id,
        );

        if (status === 200) {
          this.id = '';

          await this.$swal.fire({
            title: 'Proceso de cambio de contraseña',
            icon: 'success',
            html: `
             <p>Siga con el proceso de cambio de contraseña. Ingrese al correo ${data} para continuar</p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          });

          await this.$router.push({ name: 'Home' });
          this.isLoading = false;
        }
      } catch (error) {
        await this.$swal.fire({
          title: 'No existe la cuenta',
          icon: 'error',
          html: `
             <p>Parece que usted no tiene una cuenta asignada. Comuníquese con nuestros asesores a la línea telefónica 604 575 18 00 o a nuestro WhatsApp 320 706 33 13.</p>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#96092d',
          confirmButtonText: 'Aceptar',
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-elements-form-register {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
