<template>
  <Loading v-if="isLoading" />
  <div class="data-user px-4">
    <div class="columns">
      <div class="column">
        <div
          class="is-flex mb-12"
          style="flex-direction: row; justify-content: space-between; flex-wrap: wrap"
        >
          <div
            class="is-flex is-align-items-start mb-5"
            style="justify-content: start; flex-wrap: nowrap; padding-top: 1em"
          >
            <img
              src="../assets/img/general-information.svg"
              alt="Informaci&oacute;n General"
              class="mr-2"
            />
            <h1 class="title is-4">Contratos</h1>
          </div>
          <div class="is-flex is-align-items-start mb-5 notification-alert">
            <!-- <img src="../assets/img/notification-bell.svg" /> -->
            <img
              class="notification-alert-icon mr-2"
              src="../assets/img/notification-bell.svg"
              alt="Notificaci&oacute;n"
            />
            <p>
              ¿Ya conoces nuestra promoción anual y semestral para el pago de tu contrato exequial?
              <br />
              Comunícate a la línea 6045755050 o al WhatsApp 320 706 33 13
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="data-contracts px-4">
    <div
      class="columns is-multiline is-marginless has-text-centered is-boxed is-vcentered mb-1
      card-border-div"
      v-for="(item, index) in register"
      :key="index"
    >
      <div class="column is-2-tablet is-1-desktop px-4 py-5">
        <div class="columns">
          <div class="column">
            <img
              v-if="item.petContract"
              src="../assets/img/pet.svg"
              alt="Imagen tipo de contrato"
            />
            <img
              v-if="!item.petContract"
              src="../assets/img/user-contract.svg"
              alt="Imagen tipo de contrato"
            />&nbsp;
            <span>Contrato {{ item.contract }}</span>
          </div>
        </div>
      </div>
      <div class="column is-2-tablet is-2-desktop px-4 py-5">
        <router-link
          class="is-link"
          v-bind:to="{ name: 'ListBeneficiaries', params: { id: item.contract } }"
          >Beneficiarios
        </router-link>
      </div>
      <div class="column is-2-tablet is-2-desktop px-4 py-5">
        <router-link :to="{ name: 'Payment', params: { id: item.contract } }">
          Hist&oacute;ricos de pagos
        </router-link>
      </div>
      <div class="column is-2-tablet is-2-desktop px-4 py-5">
        <span style="color: #3273dc">Vigente hasta: </span>
        <span>{{ formatDate(item.validUntilDate) }}</span>
      </div>
      <div class="column is-2-tablet is-1-desktop px-4 py-5">
        <span>
          <img :src="item.coverageRadar" alt="Imagen Sem&aacute;foro" width="70" />
        </span>
      </div>
      <div class="column is-2-tablet is-4-desktop px-4 py-5">
        <button
          class="button bg-color-blue has-text-white is-small"
          @click="copyOfTheContract(item.contract)"
        >
          Descargar certificado</button
        >&nbsp;
        <button class="button is-primary is-small" @click="contractRequest(item.contract)">
          Solicitar contrato
        </button>
      </div>
      <div v-if="item.isUpdateAvailable" class="card-alert-background">
        Se&ntilde;or usuario, este contrato se encuentra desactualizado,
        por favor actualicelo dando click en el boton para poder seguir disfrutando del plan.
        <button class="button bg-color-blue has-text-white is-small"
          @click="updateContract(item.contract)">
          Actualizar plan
        </button>
      </div>
    </div>
  </div>
  <div v-if="numberRegister > limit" class="px-4 mt-5">
    <nav class="pagination is-rounded" role="navigation" aria-label="pagination">
      <router-link
        :to="{ query: { page: currentPage > 1 ? currentPage - 1 : 1 } }"
        class="pagination-previous"
        :disabled="currentPage === 1 ? true : null"
      >
        Atr&aacute;s
      </router-link>
      <router-link
        :to="{ query: { page: numberPages > currentPage ? currentPage + 1 : numberPages } }"
        class="pagination-next"
        :disabled="currentPage === numberPages ? true : null"
      >
        Siguiente
      </router-link>
      <ul class="pagination-list">
        <li v-for="(item, index) in numberPages" :key="index">
          <router-link
            v-bind:class="{ 'pagination-link': true, 'is-current': currentPage === index + 1 }"
            :to="{ query: { page: item } }"
          >
            {{ index + 1 }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import Loading from '@/components/Loading.vue';

export default {
  name: 'Contracts',
  components: { Loading },
  data() {
    return {
      currentPage: 1,
      register: [],
      numberRegister: '',
      numberPages: '',
      limit: 5,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['appToken', 'dataUser']),
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler(page) {
        this.currentPage = page || 1;
        this.dataPages(parseInt(this.currentPage, 10));
      },
    },
  },
  methods: {
    async dataPages(page) {
      try {
        const skip = (page - 1) * this.limit + 1;
        const elementsContracts = await this.axios.get(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/beneficiaries/contracts`,
          {
            params: {
              limit: this.limit,
              offset: skip,
            },
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );
        this.register = elementsContracts.data.data;
        this.numberRegister = elementsContracts.data.size;
        this.numberPages = Math.ceil(this.numberRegister / this.limit);
        this.currentPage = page;
      } catch (error) {
        await this.$swal.fire({
          title: 'Sesi&oacute;n de usuario',
          icon: 'error',
          html: `
             <p>
             La sesi&oacute;n de usuario ha expirado o no est&aacute; autorizado para ver esta secci&oacute;n.
             </p>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#96092d',
          timer: 3000,
        });
        await this.actionCloseSessionUser();
        await this.$router.push({ name: 'Home' });
      }
    },
    ...mapActions(['actionCloseSessionUser']),
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    async copyOfTheContract(id) {
      try {
        this.isLoading = true;
        const response = await this.axios(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/contracts/download/${id}`,
          {
            responseType: 'blob',
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );
        const url = window.URL.createObjectURL(response.data);
        const linkFile = document.createElement('a');
        linkFile.href = url;
        linkFile.download = `certificado_${id}.pdf`;
        document.body.appendChild(linkFile);
        linkFile.click();
        linkFile.remove();
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
      } catch (error) {
        this.actionCloseSessionUser();
        this.$router.push({ name: 'Home' });
      }
    },
    async contractRequest(id) {
      try {
        this.isLoading = true;
        const response = await this.axios.post(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/contracts/copy/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );

        if (response.status === 200) {
          await this.$swal.fire({
            title: 'Solicitud enviada con exito',
            icon: 'success',
            html: `
             <p>Uno de nuestros asesores se pondr&aacute; en contacto con usted. Recuerde actualizar sus n&uacute;meros telef&oacute;nicos y correo electr&oacute;nico.</p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          });
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async updateContract(id) {
      try {
        this.isLoading = true;
        const response = await this.axios.patch(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/contracts/${id}/payment-fee`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        );

        if (response.status === 200) {
          await this.$swal.fire({
            title: 'Solicitud enviada con exito',
            icon: 'success',
            html: `
             <p>El contrato se ha actualizado correctamente.</p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          }).then(() => {
            window.location.reload();
          });
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
div.data-contracts > div.columns:nth-child(even) {
  background-color: #f3f3f3;
}

div.data-contracts > div.columns:nth-child(odd) {
  background-color: #f8f8f8;
}
</style>
