<template>
  <FormCreatePassword></FormCreatePassword>
</template>

<script>
import FormCreatePassword from '@/components/FormCreatePassword.vue';

export default {
  name: 'CreatePassword',
  components: {
    FormCreatePassword,
  },
};
</script>

<style></style>
