<template>
  <div class="section-user">
    <header class="container">
      <div class="section-logo py-4">
        <Logo />
      </div>
    </header>
    <div class="container main-content-structure">
      <main class="has-background-white box-rounded py-5 mb-6">
        <span
          class="is-flex"
          style="
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 1em 0 1em;
          "
        >
          <a class="column user-name-title" v-if="getNameUser !== UNDEFINED" @click.prevent="">
            <span class="hast-text-secondary"> ¡Hola {{ getNameUser }}!</span>
          </a>
          <div class="is-flex is-align-items-start mb-5 notification-alert">
            <!-- <img src="../assets/img/notification-bell.svg" /> -->
            <img
              class="notification-alert-icon mr-2"
              src="../assets/img/notification-bell.svg"
              alt="Notificaci&oacute;n"
            />
            <p>
              Si desea afiliar otro beneficiario o cambiar los datos de sus beneficiarios actuales,
              <br />
              comuníquese ala línea 6045755050.
            </p>
          </div>
        </span>
        <hr class="bg-color-secondary my-0 mb-4" />
        <Beneficiaries />
      </main>
      <footer class="has-background-white box-rounded mb-6">
        <Footer />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Footer from '@/components/Footer.vue';
import Beneficiaries from '@/components/Beneficiaries.vue';
import Logo from '@/components/Logo.vue';

export default {
  name: 'ListBeneficiaries',
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  components: {
    Beneficiaries,
    Logo,
    Footer,
  },
  methods: {
    consult() {
      this.axios
        .get(
          `${process.env.VUE_APP_API_ROOT_CONTRACT}contractsms/api/contracts/${this.id}/beneficiary`,
          {
            headers: {
              Authorization: `Bearer ${this.appToken}`,
            },
          },
        )
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    },
  },
  computed: {
    ...mapState(['appToken']),
    ...mapGetters(['getNameUser']),
  },
};
</script>

<style scoped></style>
