<template>
  <Loading v-if="isLoading" />
  <section class="background-login px-2">
    <div class="container">
      <div>
        <Logo />
      </div>
    </div>
    <div class="container mt-5">
      <div class="columns">
        <div class="column">
          <div class="form-container-login card is-relative">
            <div class="img-header-icon">
              <div class="container-header-icon">
                <img
                  src="../assets/img/icon-login-user.svg"
                  width="32"
                  height="32"
                  alt="Usuario"
                  class="is-block"
                />
              </div>
            </div>
            <div class="card-content mt-5">
              <div class="has-text-centered">
                <span class="title is-6" style="border-bottom: #96092d 2px solid">
                  Inicio de Sesi&oacute;n
                </span>
                &nbsp;&nbsp;
                <router-link to="/registro" class="has-text-primary title is-6">
                  Registrarse
                </router-link>
                &nbsp;&nbsp;
                <router-link :to="{ name: 'RecoveryPassword' }" class="has-text-primary title is-6">
                  Recuperar contrase&ntilde;a
                </router-link>
              </div>
              <Form id="form-register" :validation-schema="schema" @submit="sendForm">
                <div class="field mt-5">
                  <div class="control has-icons-left">
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'envelope']" />
                    </span>
                    <Field
                      v-model="id"
                      name="id"
                      class="input"
                      placeholder="Ejemplo: 71211438"
                      type="text"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="id" class="has-text-primary" as="p" />
                  </div>
                  <p class="help">
                    <span>Ingrese el n&uacute;mero de c&eacute;dula</span>
                  </p>
                </div>
                <div class="field mt-5">
                  <div class="control has-icons-left">
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'unlock-alt']" />
                    </span>
                    <span class="password-eye">
                      <Field
                        v-show="showPassword"
                        v-model="password"
                        name="password"
                        class="input"
                        placeholder="Contraseña de usuario"
                        type="text"
                        :validateOnInput="true"
                      />
                      <Field
                        v-show="!showPassword"
                        v-model="password"
                        name="password"
                        class="input"
                        placeholder="Contraseña de usuario"
                        type="password"
                        :validateOnInput="true"
                      />
                      <a class="button" @click="toggleShow">
                        <span class="icon is-small" @click="toggleShow">
                          <font-awesome-icon v-if="showPassword" :icon="['far', 'eye']" />
                          <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
                        </span>
                      </a>
                    </span>
                    <ErrorMessage name="password" class="has-text-primary" as="p" />
                  </div>
                  <p class="help">Escriba su contrase&ntilde;a</p>
                </div>
                <div class="field mt-5">
                  <p class="has-text-centered">
                    <button class="button is-primary">Ingresar</button>
                  </p>
                </div>
              </Form>
            </div>
          </div>
          <div
            class="mt-3 content bg-color-blue pt-3 pb-3 pr-3 pl-3"
            style="color: white; border-radius: 0.6rem; max-width: 480px"
          >
            <ul class="m-0 pl-2" style="list-style: circle; font-size: 0.9rem">
              <li class="mb-2">
                <p>
                  Realice
                  <b style="color: #ffffff">consultas relacionadas con</b>: contratos vigentes /
                  beneficiarios / hist&oacute;ricos de Pagos / actualizaci&oacute;n de datos
                  personales.
                </p>
              </li>
              <li class="mb-2">
                <p>
                  Si es su primer ingreso, de clic en el bot&oacute;n
                  <b style="color: #ffffff">"Registrarse"</b>. A continuaci&oacute;n, complete los
                  datos solicitados y siga las instrucciones. Si presenta alguna inquietud, puede
                  comunicarse a la línea telefónica (604) 575 50 50.
                </p>
              </li>
              <li class="mb-2">
                <p>
                  Si ya activó su cuenta y tiene contrase&ntilde;a, haga clic en el botón
                  <b style="color: #ffffff">"Ingresar"</b>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ErrorMessage, Field, Form } from 'vee-validate';
import { mapActions } from 'vuex';
import * as yup from 'yup';
import axios from 'axios';
import Loading from '@/components/Loading.vue';
import Logo from './Logo.vue';

export default {
  name: 'Login',
  data() {
    const schema = yup.object({
      id: yup
        .string()
        .required('Requerido')
        .matches(/[0-9]+$/, { message: 'Solo números enteros' })
        .min(6, 'Mínimo 6 caracteres')
        .max(11, 'Máximo 11 caracteres'),
      password: yup
        .string()
        .required('Contraseña requerida')
        .min(8, 'La contraseña no puede ser inferior a 8 caracteres'),
    });
    return {
      schema,
      id: '',
      password: '',
      isLoading: false,
      showPassword: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? 'Ocultar' : 'Mostrar';
    },
  },
  components: {
    Field,
    ErrorMessage,
    Form,
    Logo,
    Loading,
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async sendForm() {
      try {
        this.isLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_API_ROOT + process.env.VUE_APP_LOGIN,
          {
            id: this.id.trim(),
            password: this.password.trim(),
          },
        );
        const { status } = response;
        const { authorization } = response.headers;
        if (status === 200) {
          if (authorization !== '' && authorization) {
            this.actionGetUser(authorization);
            this.isLoading = false;
          } else {
            await this.$swal.fire({
              title: 'Error de inicio de sesión',
              icon: 'error',
              html: `
             <p>En el momento el inicio de sesión está fuera de servicio. Pruebe mas tarde</p>
            `,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#96092d',
              confirmButtonText: 'Accept',
              timer: 5000,
            });
            this.password = '';
            this.id = '';
          }
        }
      } catch (error) {
        this.isLoading = false;
        await this.$swal.fire({
          title: 'Error de inicio de sesión',
          icon: 'error',
          html: `
             <p>Usuario o contraseña incorrectos</p>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#96092d',
          confirmButtonText: 'Aceptar',
          timer: 5000,
        });
        this.password = '';
        this.id = '';
      }
    },
    ...mapActions(['actionGetUser']),
  },
};
</script>

<style lang="scss">
.form-container-login {
  max-width: 480px;
}
</style>
