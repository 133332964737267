<template>
  <FormRecoveryPassword />
</template>

<script>
import FormRecoveryPassword from '../components/FormRecoveryPassword.vue';

export default {
  name: 'RecoveryPassword',
  components: {
    FormRecoveryPassword,
  },
};
</script>

<style lang="scss"></style>
