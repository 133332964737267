<template>
  <div class="section-user px-2">
    <header class="container">
      <div class="section-logo py-4">
        <Logo />
      </div>
    </header>
    <div class="container">
      <main class="has-background-white box-rounded py-5 mb-6">
        <a class="column user-name-title" v-if="getNameUser !== UNDEFINED" @click.prevent="">
          <span class="hast-text-secondary"> ¡Hola {{ getNameUser }}!</span>
        </a>
        <hr class="bg-color-secondary" />
        <ListPayments />
      </main>
      <footer class="has-background-white box-rounded mb-6">
        <Footer />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ListPayments from '@/components/ListPayments.vue';
import Logo from '@/components/Logo.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Payments',
  components: {
    ListPayments,
    Logo,
    Footer,
  },
  computed: { ...mapGetters(['getNameUser']) },
};
</script>
