<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['actionReadAppToken']),
  },
  created() {
    this.actionReadAppToken();
  },
};
</script>
