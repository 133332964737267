<template>
  <div class="section-user px-2">
    <header class="container">
      <div class="section-logo py-4">
        <Logo />
      </div>
    </header>
    <div class="container main-content-structure">
      <main class="has-background-white box-rounded p-5">
        <a class="column user-name-title" v-if="getNameUser !== UNDEFINED" @click.prevent="">
          <span class="hast-text-secondary"> ¡Hola {{ getNameUser }}!</span>
        </a>
        <hr class="bg-color-secondary" />
        <FormPasswordChange />
      </main>
      <footer class="has-background-white box-rounded mt-4">
        <Footer />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormPasswordChange from '@/components/FormPasswordChange.vue';
import Logo from '@/components/Logo.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'PasswordChange',
  components: {
    FormPasswordChange,
    Logo,
    Footer,
  },
  methods: {},
  computed: { ...mapGetters(['getNameUser']) },
};
</script>
