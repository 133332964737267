<template>
  <div class="section-user px-2">
    <header>
      <div class="section-logo py-4">
        <Logo />
      </div>
    </header>
    <div class="profile-container-grid">
      <aside class="user-data-section has-background-white box-rounded px-2 py-5 mb-3">
        <div class="column is-12-desktop center-items-flexbox">
          <span class="avatar">
            <img src="../assets/img/avatar.png" alt="Avatar" />
          </span>
          <a class="user-name-title" v-if="getNameUser !== UNDEFINED" @click.prevent="">
            <span class="hast-text-secondary"> ¡Hola {{ getNameUser }}!</span>
          </a>
        </div>
        <hr class="bg-color-secondary" />
        <FormUserData />
      </aside>
      <main class="contracts-section has-background-white box-rounded py-5 mb-3">
        <Contracts />
      </main>
    </div>
    <footer class="has-background-white box-rounded wide-footer">
      <Footer />
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Contracts from '@/components/Contracts.vue';
import FormUserData from '@/components/FormUserData.vue';
import Logo from '../components/Logo.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Profile',
  components: {
    Logo,
    Contracts,
    FormUserData,
    Footer,
  },
  computed: { ...mapGetters(['getNameUser']) },
};
</script>
