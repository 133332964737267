<template>
  <div>
    <FormRegister></FormRegister>
  </div>
</template>

<script>
import FormRegister from '@/components/FormRegister.vue';

export default {
  name: 'Register',
  components: {
    FormRegister,
  },
};
</script>

<style></style>
