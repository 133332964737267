<template>
  <p class="mb-1 help has-text-blue">S&iacute;guenos en nuestras redes sociales</p>
  <p class="mt-0">
    <a target="_blank" href="https://www.youtube.com/channel/UCEq_GqIHQh0mJuo84hEQaZw">
      <img src="../assets/img/youtube.svg" alt="Instagram" height="36" width="36" /> </a
    >&nbsp;&nbsp;
    <a target="_blank" href="https://www.instagram.com/fsanvicente/?hl=es">
      <img src="../assets/img/instagram.svg" alt="Instagram" height="36" width="36" /> </a
    >&nbsp;&nbsp;
    <a target="_blank" href="https://es-la.facebook.com/funerariasanvicente/">
      <img src="../assets/img/facebook.svg" alt="Facebook" height="36" width="36" /> </a
    >&nbsp;&nbsp;
  </p>
  <p class="help">© Copyright 2020 - Funeraria San Vicente S.A. Todos los derechos reservados</p>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style></style>
