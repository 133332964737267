<template>
  <Loading v-if="isLoading" />

  <section class="background-login px-2">
    <div class="container">
      <div class="wrapper-elements-form-register">
        <div>
          <Logo />
        </div>
        <div class="form-container-login card is-relative py-3">
          <div class="img-header-icon">
            <div class="container-header-icon">
              <img
                src="../assets/img/user.svg"
                width="32"
                height="32"
                alt="Usuario"
                class="is-block"
              />
            </div>
          </div>
          <div class="card-content mt-5">
            <form id="form-register" @submit.prevent="sedFormRegister">
              <h2 class="has-text-centered">Registrar Usuario</h2>
              <div class="field mt-5">
                <div class="control has-icons-left">
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'address-card']" />
                  </span>
                  <input
                    class="input"
                    type="text"
                    required
                    placeholder="N&uacute;mero de C&eacute;dula"
                    maxlength="11"
                    minlength="6"
                    pattern="^[0-9]+"
                    v-model="id"
                  />
                </div>
                <p class="help">
                  Escriba el n&uacute;mero de c&eacute;dula registrado en la Funeraria San Vicente
                </p>
              </div>
              <div class="field mt-5">
                <p class="has-text-centered">
                  <button class="button is-primary">Registrar</button>
                </p>
                <p class="has-text-centered">
                  <router-link to="/" class="has-text-primary">Inicio de sesi&oacute;n</router-link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '@/components/Loading.vue';
import Logo from './Logo.vue';

export default {
  name: 'FormRegister',
  components: { Loading, Logo },
  data() {
    return {
      id: '',
      isLoading: false,
    };
  },
  methods: {
    async sedFormRegister() {
      this.isLoading = true;
      try {
        const register = await this.axios.get(
          `${process.env.VUE_APP_API_ROOT}${process.env.VUE_APP_REGISTER}${this.id}`,
        );
        if (register.status === 200 || register.status === 201) {
          this.id = '';
          const resp = `
                        <p>
                        <strong>
                        Revise el correo ${register.data} para terminar el proceso.
                        Si no lo visualiza en la bandeja de entrada, revise la bandeja de spam.
                        </strong>
                        </p>
                        `;
          await this.$swal.fire({
            title: 'Registro exitoso.',
            icon: 'success',
            html: resp,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          });
          this.isLoading = false;
        }
      } catch (error) {
        if (error.response !== undefined) {
          this.isLoading = true;
          const codeResponse = error.response.status;
          switch (codeResponse) {
            case 400:
              console.log(codeResponse);
              console.log(error.response);
              if (error.response.data.errorCode === 'NOT_USER_FOUND') {
                await this.$swal.fire({
                  title: 'Sin contrato',
                  icon: 'warning',
                  html: '<strong>Parece que usted no tiene una cuenta asignada. Comuníquese con nuestros asesores a la línea telef&oacute;nica 604 575 18 00 o a nuestro WhatsApp 320 706 33 13</strong>',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#96092d',
                  confirmButtonText: 'Aceptar',
                });
              }

              if (error.response.data.errorCode === 'Business_EXCEPTION') {
                await this.$swal.fire({
                  title: 'Sin contrato',
                  icon: 'warning',
                  html: '<strong>Parece que usted no tiene una cuenta asignada. Comuníquese con nuestros asesores a la línea telef&oacute;nica 604 575 18 00 o a nuestro WhatsApp 320 706 33 13</strong>',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#96092d',
                  confirmButtonText: 'Aceptar',
                });
              }

              if (error.response.data.errorCode === 'NOT_EMAIL_FOUND') {
                await this.$swal.fire({
                  title:
                    'Parece que usted no tiene una cuenta asignada en nuestro canal virtual. Comuníquese a la línea telefónica 604 575 18 00 o al WhatsApp 320 706 33 13',
                  icon: 'warning',
                  html: '<strong>Si su contrato exequial está activo por medio de una empresa (contrato empresarial) comuníquese al 320 620 17 40.</strong>',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#96092d',
                  confirmButtonText: 'Aceptar',
                });
              }

              if (error.response.data.errorCode === 'USER_ALREADY_EXIST') {
                const responseUserExist = `
                    <strong>
                      El usuario ya existe.
                      <a href="/" class="has-text-primary">Iniciar Sesi&oacute;n</a>
                    </strong>`;

                await this.$swal.fire({
                  title: 'El usuario ya existe',
                  icon: 'warning',
                  html: responseUserExist,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#96092d',
                  confirmButtonText: 'Aceptar',
                });
              }
              break;
            default:
              break;
          }

          this.isLoading = false;
        } else {
          this.isLoading = true;
          await this.$swal.fire({
            title: 'Error de al crear la cuenta',
            icon: 'error',
            html: '<strong>Hay problemas en el momento en la creaci&oacute;n de la cuenta. Vuelva a intentar mas tarde</strong>',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#96092d',
            confirmButtonText: 'Aceptar',
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-elements-form-register {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
