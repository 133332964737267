<template>
  <div class="section-user px-2">
    <header class="container">
      <div class="section-logo py-4">
        <Logo />
      </div>
    </header>
    <div class="container">
      <main class="has-background-white box-rounded p-5">
        <NavLogin />
        <hr class="bg-color-secondary" />
        <FormUserData />
      </main>
    </div>
  </div>
</template>

<script>
import NavLogin from '@/components/NavLogin.vue';
import FormUserData from '@/components/FormUserData.vue';
import Logo from '../components/Logo.vue';

export default {
  name: 'UserData',
  components: {
    Logo,
    NavLogin,
    FormUserData,
  },
};
</script>

<style lang="scss"></style>
