<template>
  <div class="pt-3 pb-1 has-text-centered-mobile header-section">
    <router-link :to="{ name: 'dashboard' }" style="text-decoration: none">
      <img
        src="../assets/img/logo-funeraria.svg"
        alt="Funeraria San Vicente"
        width="200"
      />&nbsp;&nbsp;
      <img src="../assets/img/contratos-exequiales.svg" alt="Contratos exequiales" width="200" />
    </router-link>
    <NavLogin
      v-if="
        $route.name !== 'Home' &&
        $route.name !== 'Register' &&
        $route.name !== 'CreatePassword' &&
        $route.name !== 'RecoveryPassword' &&
        $route.name !== 'NotFound'
      "
    />
  </div>
</template>

<script>
import NavLogin from '@/components/NavLogin.vue';

export default {
  name: 'Logo',
  components: {
    NavLogin,
  },
};
</script>
