<template>
  <nav
    class="box-rounded is-flex is-flex-direction-row"
    role="navigation"
    aria-label="main navigation"
  >
    <div id="navbar-simple" class="navbar-menu">
      <div class="navbar-end">
        <router-link
          :to="{ name: 'dashboard' }"
          class="navbar-item hast-text-secondary style-item-navbar"
          v-if="
            $route.name === 'ListBeneficiaries' ||
            $route.name === 'Payment' ||
            $route.name === 'RecoveryPasswordUser' ||
            $route.name === 'Notifications'
          "
        >
          <span class="header-menu-icon">
            <span class="icon has-text-primary">
              <img class="icon-size" src="../assets/img/contratos.svg" alt="Contratos" />
            </span>
            <span>Contratos</span>
          </span>
        </router-link>
        <a
          :href="'https://pagos.funerariasanvicente.com.co'"
          target="_blank"
          class="navbar-item hast-text-secondary header-menu-option"
        >
          <span class="header-menu-icon">
            <span class="icon has-text-primary">
              <img src="../assets/img/icon-payment.svg" alt="Pago en línea" />
            </span>
            <span>Pago en l&iacute;nea</span>
          </span>
        </a>
        <router-link :to="{ name: 'Notifications' }" class="navbar-item hast-text-secondary">
          <span class="header-menu-icon">
            <span class="icon has-text-primary">
              <img src="../assets/img/contactanos.svg" alt="Contáctanos" />
            </span>
            <span>Cont&aacute;ctenos</span>
          </span>
        </router-link>
        <router-link to="/" class="navbar-item hast-text-secondary" @click="actionCloseSessionUser">
          <span class="header-menu-icon">
            <span class="icon has-text-primary header-menu-icon-image">
              <img src="../assets/img/close-session.svg" alt="Cerrar Sesión" />
            </span>
            <span class="header-menu-icon-text">Cerrar Sesi&oacute;n</span>
          </span>
        </router-link>
      </div>
    </div>
    <div class="navbar-brand">
      <a
        @click="toogleMenu"
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarSimple"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NavLogin',
  methods: {
    toogleMenu() {
      const btn = document.querySelector('#navbar-simple');
      btn.classList.toggle('is-block');
    },
    ...mapActions(['actionCloseSessionUser']),
  },
};
</script>

<style scoped>
.style-item-navbar {
  font-size: 0.9rem;
}
</style>
